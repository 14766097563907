<template>
  <div class="schedule">
    <Title :title="title" :description="description"></Title>
    <!-- {{dayList}} {{scheduleList}} -->
    <p v-if="dayList.length==0" class="no-schedule">敬请期待</p>
    <div class="content-wrapper" v-else>
      <section class="schedule-content">
        <div class="schedule-time">
          <div
            class="time-item"
            v-for="(item, index) in dayList"
            :key="index"
            :data-time="item.timeStamp"
            :class="item.timeStamp === selectedDate ? 'selected' : ''"
            @click="toggleDay(index, $event)"
          >
            <h4>DAY{{ index + 1 }}</h4>
            <p>{{ item.time }} {{ item.week }}</p>
          </div>
        </div>
        <div
          class="schedule-day"
          v-for="(scheduleItem, itemId) in Object.values(scheduleList)"
          :key="itemId"
          ref="schedule"
        >
          <h4 class="day-title">DAY{{ itemId + 1 }}</h4>
          <table class="schedule-item">
            <div class="time-container">
              <div
                class="time-list"
                v-for="(time, k) in Object.values(timeList)[itemId]"
                :key="k"
              >
                <td class="time-title"><img src="../assets/img/time.png" /></td>
                <td class="time-header" v-for="(t, index) in time" :key="index">
                  {{ t.split("-")[0] }}
                  <br />
                  -
                  <br />
                  {{ t.split("-")[1] }}
                </td>
              </div>
            </div>
            <div class="type-list">
              <div
                class="type-item"
                v-for="(daySchedule, j) in scheduleItem"
                :key="j"
              >
                <tr
                  class="item-container"
                  v-for="(item, index) in daySchedule"
                  :key="index"
                >
                  <td class="schedule-title" @click="toSubject(item.id)">
                    <h4>{{ item.venueName }}</h4>
                    <p>出品人：{{ item.name }}</p>
                    <!-- <p @click="item.producerId?toTeacher(item.producerId):''">出品人：{{ item.name }}</p> -->
                  </td>
                  <!-- {{item.detail.length}} -->
                  <!-- {{item.detail}} -->
                  <!-- 敬请期待 -->
                  <div
                    class="no-detail"
                    v-if="!item.detail || item.detail.length <= 0"
                  >
                    <td class="schedule-detail no-schedule">敬请期待</td>
                    <td class="schedule-detail no-schedule">敬请期待</td>
                    <td class="schedule-detail no-schedule">敬请期待</td>
                    <td
                      class="schedule-detail no-schedule"
                      v-show="index === 1"
                    >
                      敬请期待
                    </td>
                  </div>
                  <!-- 课程 -->
                  <slot v-else v-for="(schedule, i) in item.detail">
                    <td v-if="schedule=='敬请期待'" class="schedule-detail no-schedule" :key="'_'+i">敬请期待</td>
                    <td
                      class="schedule-detail"
                      v-else
                      :key="i"
                      :data-courseid="schedule.courseId"
                      @click="toCourse(schedule.courseId)"
                    >
                      <!-- {{schedule}} -->
                      <h4>{{ schedule.courseTitle }}</h4>
                      <div
                        class="lecturer-list"
                        v-for="(lecturer, id) in schedule.lecturers"
                        :key="id"
                      >
                        <p class="name">{{ lecturer.name }}</p>
                        <p class="company">
                          {{ lecturer.company }}<br />{{ lecturer.position }}
                        </p>
                      </div>
                    </td>
                  </slot>
                </tr>
              </div>
            </div>
          </table>
        </div>
      </section>
      <section class="mob-schedule">
        <div class="mob-schedule-time">
          <div
            class="mob-time-item"
            v-for="(item, index) in dayList"
            :key="index"
            :data-time="item.timeStamp"
            :class="item.timeStamp == selectedDate ? 'selected' : ''"
            @click="toggleDay(index, $event)"
          >
            <div>DAY{{ index + 1 }}</div>
          </div>
        </div>
        <div
          class="mob-schedule-list"
          :class="i === selectedDate ? 'actived' : ''"
          v-for="(typeList, i) in scheduleList"
          :key="i"
        >
          <div class="mob-type-list">
            <div
              class="mob-type-item"
              v-for="(type, j) in typeList"
              :key="j"
              :data-id="j"
              :class="j === selectedSchedule ? 'selected' : ''"
              @click="toggleSchedule"
            >
              {{ type[0] && type[0].venueName
              }}{{ type[1] && "/" + type[1].venueName }}
            </div>
          </div>
          <div
            class="mob-speech-wrapper"
            v-for="(type, j) in typeList"
            :key="j"
            :class="j === selectedSchedule ? 'actived' : ''"
          >
            <div class="mob-speech-list" v-for="(s, k) in type" :key="k">
              <div
                class="mob-speech-item"
                v-for="(speech, key) in s.detail"
                :key="key"
                :data-courseId="speech.courseId"
                @click="toCourse(speech.courseId)"
              >
                <h4>{{ speech.courseTitle }}</h4>
                <div
                  class="mob-lecturer-list"
                  v-for="(lecturer, l) in speech.lecturers"
                  :key="l"
                >
                  <p class="lecturer">
                    <span>{{ lecturer.name }}</span
                    >{{ lecturer.company }} {{ lecturer.position }}
                  </p>
                </div>
                <p class="time">
                  <span class="glyphicon glyphicon-time"></span>
                  {{ speech.time }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import Title from "@/components/Title";
import { sid, getSpeechInfoNew, getScheduleInfo } from "@/utils/api.js";

const weekList = {
  0: "星期日",
  1: "星期一",
  2: "星期二",
  3: "星期三",
  4: "星期四",
  5: "星期五",
  6: "星期六",
};
const amTimeStr =
  "9:00-10:00,10:00-11:00,11:00-12:00,8:45-9:35,9:35-10:25,10:25-11:15,11:15-12:05";
const pmTimeStr = "13:30-14:30,14:30-15:30,15:50-16:50,16:50-17:50";
export default {
  name: "NewSchedule",
  components: {
    Title,
  },
  data() {
    return {
      title: "大会日程",
      description: "年度技术创新及研发实践的架构案例",
      selectedDate: "",
      selectedSchedule: "1", // 移动端选中的日程
      dayList: [], // 日期列表
      scheduleList: {}, // 日程列表
      timeList: {},
      totalScheduleList: [],
    };
  },
  mounted() {
    this.getSpeech();
    this.getSchedule();
  },
  methods: {
    toggleDay(index, e) {
      this.selectedDate = e.currentTarget.dataset.time;
      this.selectedSchedule = "1";
      window.scrollTo(0, this.$refs.schedule[index].offsetTop - 121);
    },
    toggleSchedule(e) {
      this.selectedSchedule = e.target.dataset.id;
    },
    toCourse(e) {
      this.$router.push({
        path: "/2023sz/course",
        name: "ThreeSZCourse",
        // query: { id: e.target.dataset.courseid },
        query: { id:e }
      });
    },
    toTeacher(e) {
      this.$router.push({
        path:"/2023sz/teacher",
        name:"ThreeSZTeacher",
        query:{ id:e }
      });
    },
    toSubject(e) {
      this.$router.push({
        path:"/2023sz/subject",
        name:"ThreeSZSubject",
        query:{ id:e }
      });
    },
    // 1. 日期 DAY1 2021.07.30 星期五
    // 2. 每天的日程 DAY1 日程
    // 跳转日程详情页需传入courseid
    // { 1627274400: {1:[{},{}],2:[{},{}]}}
    filterDayList(dateList) {
      Object.keys(dateList).map((item) => {
        this.dayList.push({
          timeStamp: item,
          time: dayjs(item * 1000).format("YYYY.MM.DD"),
          week: weekList[new Date(item * 1000).getDay()],
        });
      });
    },
    filterSpeechList(speechList) {
      const totalList = [];
      //   根据日期对日程进行分类
      for (const [key, value] of Object.entries(speechList)) {
        // console.log(key);
        // console.log(value);
        const { am: amList, pm: pmList } = value;
        // 遍历整合上午的日程
        for (const [type, val] of Object.entries(amList)) {
          // console.log(type);
          // console.log(val);
          for (const [k, v] of Object.entries(val)) {
            // console.log(k);
            // console.log(v);
            const timeStr = val.time[0] + "-" + val.time[1];
            if (k !== "time" && !Array.isArray(v)) {
              // 每个日程详情内添加会议时间和groupId
              v.timeStamp = key;
              v.time = timeStr;
              v.type = k;
              v.addType = "am";
              totalList.push(v);
            } else {
              // 添加上午的时间列表
              if (this.timeList[key] === undefined) {
                this.timeList[key] = { am: [], pm: [] };
              }
              if (
                this.timeList[key].am.indexOf(timeStr) <= -1 &&
                this.timeList[key].am.length < 3
              ) {
                this.timeList[key].am.push(timeStr);
              }
            }
          }
        }
        // 遍历整合下午的日程
        for (const [type, val] of Object.entries(pmList)) {
          // console.log(type);
          //   console.log(val);
          for (const [k, v] of Object.entries(val)) {
            // console.log(k);
            // console.log(v);
            const timeStr = val.time[0] + "-" + val.time[1];
            if (k !== "time" && !Array.isArray(v)) {
              v.timeStamp = key;
              v.time = timeStr;
              v.type = k;
              v.addType = "pm";
              totalList.push(v);
            } else {
              if (this.timeList[key] === undefined) {
                this.timeList[key] = { am: [], pm: [] };
              }
              if (this.timeList[key].pm.indexOf(timeStr) <= -1) {
                this.timeList[key].pm.push(timeStr);
              }
            }
          }
        }
        this.totalScheduleList = totalList;
        // console.log("totalScheduleList ", this.totalScheduleList);
        // console.log("this.scheduleList ", this.scheduleList);
        let times_arr = [];
        let times_data = [];
        for (const [key, value] of Object.entries(this.scheduleList)) {
          for (let k in value) {
            // console.log(value[k]);
            // "am"
            if (value[k][0]) {
              // 设置敬请期待
              // value[k][0].noDetail = [{}, {}, {}];
              // 敬请期待 + 日程
              value[k][0].detail = []
              times_arr = value[k][0].times.split(",");
              // console.log(times_arr);
              for(let t in times_arr){
                value[k][0].detail.push('敬请期待');
                times_data = this.totalScheduleList.filter(
                  (item) =>
                    item.timeStamp === key &&
                    item.type === k &&
                    item.addType === "am" && 
                    item.time == times_arr[t]
                )
                times_data.length>0 ? value[k][0].detail[t]=times_data[0] : '';
              }
              // console.log(value[k][0].detail)
              // 设置日程详情
              // value[k][0].detail = this.totalScheduleList.filter(
              //   (item) =>
              //     item.timeStamp === key &&
              //     item.type === k &&
              //     item.addType === "am"
              // );
            }
            // "pm"
            if (value[k][1]) {
              // 设置敬请期待
              // value[k][1].noDetail = [{}, {}, {}, {}];
              // 敬请期待 + 日程
              value[k][1].detail = []
              times_arr = value[k][1].times.split(",");
              // console.log(times_arr);
              for(let t in times_arr){
                value[k][1].detail.push('敬请期待');
                times_data = this.totalScheduleList.filter(
                  (item) =>
                    item.timeStamp === key &&
                    item.type === k &&
                    item.addType === "pm" && 
                    item.time == times_arr[t]
                )
                times_data.length>0 ? value[k][1].detail[t]=times_data[0] : '';
              }
              // console.log(value[k][1].detail)
              // 设置日程详情
              // value[k][1].detail = this.totalScheduleList.filter(
              //   (item) =>
              //     item.timeStamp === key &&
              //     item.type === k &&
              //     item.addType === "pm"
              // );
            }
          }
        }

        // console.log("scheduleList ", this.scheduleList);
        // console.log(this.timeList);
      }
    },
    filterAllSchedule(list) {
      const obj = {};
      list.map((item) => {
        if (obj[item.date]) {
          if (obj[item.date][item.groupId]) {
            obj[item.date][item.groupId].push(item);
          } else {
            obj[item.date][item.groupId] = [item];
          }
        } else {
          obj[item.date] = {};
          obj[item.date][item.groupId] = [item];
        }
        // 添加 am/pm
        if (amTimeStr === item.times) {
          item.addType = "am";
        } else if (pmTimeStr === item.times) {
          item.addType = "pm";
        }
      });
      // console.log(list,obj);
      return obj;
    },
    getSpeech: function () {
      // var url = "https://api.msup.com.cn/group/get-group-project/2682";
      let url = getSpeechInfoNew + sid(this.global.year);
      this.$http
        .jsonp(url)
        .then((data) => {
          return data.json();
        })
        .then((res) => {
          if (res.errno == 0) {
            // console.log("getSpeech data: ", res.data);
            this.scheduleList = this.filterAllSchedule(res.data);
          }
        });
    },
    getSchedule: function () {
      // var url = "https://api.msup.com.cn/scheduling/get-schedule/2682";
      let url = getScheduleInfo + sid(this.global.year);
      this.$http
        .jsonp(url)
        .then((data) => {
          return data.json();
        })
        .then((res) => {
          if (res.errno == 0) {
            // console.log("schedule ", res.data);
            this.totalScheduleList = this.filterSpeechList(res.data.datas);
            this.filterDayList(res.data.finishing);
            this.selectedDate = this.dayList[0] && this.dayList[0].timeStamp || '';
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.schedule .no-schedule {
  width: 100%;
  // line-height: 75px;
  // font-family: PingFangSCBold;
  // font-weight: 500;
  // font-size: 16px;
  // text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 90px;
  padding-bottom: 40px;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  color: #2630B2;
  border-bottom: 1px solid transparent;
}
@media (max-width: 767px) {
  .schedule-content {
    display: none;
  }
  .mob-schedule {
    display: block;
  }
}
@media (min-width: 768px) {
  .schedule-content {
    display: block;
  }
  .mob-schedule {
    display: none;
  }
}
@media (max-width: 750px) {
  .schedule .no-schedule {
    padding-top: 60px;
    font-size: 16px;
  }
}

// pc端样式
.schedule-content {
  padding-top: 45px;
  padding-bottom: 30px;
  .schedule-time {
    display: flex;
    justify-content: center;
    .time-item {
      margin-left: 60px;
      margin-right: 60px;
      padding-left: 50px;
      padding-right: 50px;
      width: 232px;
      height: 100px;
      color: #000;
      border: 1px solid #0b70a4;
      cursor: pointer;
      transition: all 0.3s;
      h4 {
        margin-bottom: 0;
        padding-top: 20px;
        // padding-top: 25px;
        font-size: 36px;
        font-weight: 500;
        color: inherit;
        text-align: center;
      }
      p {
        margin-bottom: 0;
        font-size: 15px;
        font-weight: 500;
        color: inherit;
        white-space: pre;
      }
      &.selected {
        color: #fff;
        background: #003177;
        p {
          font-weight: 600;
        }
      }
    }
  }
  .schedule-day {
    margin-left: auto;
    margin-right: auto;
    max-width: 1265px;
    .day-title {
      margin-top: 20px;
      margin-bottom: 20px;
      font-weight: 600;
      font-size: 40px;
      text-align: center;
      padding-top: 45px;
      padding-bottom: 35px;
    }
    .schedule-item {
      display: flex;
      .time-container {
        border-left: 1px solid #ddd;
        img {
          height: 94px;
        }
      }
      td {
        border: 1px solid #ddd;
        border-left: none;
        border-top: none;
      }
      .time-container .time-list:nth-of-type(1), 
      .type-list .type-item tr:nth-of-type(1) {
        td:nth-of-type(1) {
          border-top: 1px solid #ddd;
        }
      }
      .time-container {
        display: flex;
        flex-direction: column;
        .time-list {
          display: flex;
          flex-direction: column;
          .time-title {
            padding-right: 42px;
          }
          .time-header {
            height: 280px;
            font-size: 16px;
            background: #fafbfc;
            color: #000;
            text-align: center;
            line-height: 24px;
            padding-left: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
        }
      }
      .type-list {
        display: flex;
        .type-item {
          display: flex;
          flex-direction: column;
          .item-container {
            display: flex;
            flex-direction: column;
            &:first-child {
              height: 936px;
            }
            &:last-child {
              height: 1216px;
            }
            .schedule-title {
              width: 186px;
              height: 96px;
              text-align: left;
              color: #000;
              display: flex;
              flex-direction: column;
              justify-content: center;
              padding-left: 14px;
              cursor: pointer;
              h4 {
                font-size: 18px;
                font-weight: 700;
              }
              p {
                margin-top: 8px;
                font-size: 14px;
                cursor: pointer;
                font-weight: 600;
              }
            }
            .schedule-detail {
              flex-grow: 1;
              width: 186px;
              max-height: 280px;
              background: #fafbfc;
              text-align: center;
              font-size: 14px;
              color: #333;
              cursor: pointer;
              display: flex;
              flex-direction: column;
              justify-content: center;
              h4 {
                width: 100%;
                height: 66px;
                color: #1f63af;
                font-size: 16px;
                font-weight: 400;
                line-height: 22px;
                text-align: center;
                padding: 0 10px;
                margin-bottom: 5px;
                // 省略
                word-break: break-all;
                word-wrap: break-word;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                text-overflow: ellipsis;
                overflow: hidden;
              }
              .lecturer-list {
                p {
                  text-align: center;
                }
                .name {
                  font-size: 14px;
                  color: #333;
                  font-weight: 700;
                  // margin-top: 10px;
                  margin-bottom: 0;
                }
                .company {
                  height: 65px;
                  font-size: 14px;
                  color: #666;
                  padding-top: 5px;
                  padding-left: 5px;
                }
              }
            }
            .no-detail {
              background: #fafbfc;
              flex-grow: 1;
              display: flex;
              flex-direction: column;
              .no-schedule {
                flex-grow: 1;
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
}

// 移动端样式
.mob-schedule {
  padding-top: 30px;
  .mob-schedule-time {
    display: flex;
    justify-content: center;
    .mob-time-item {
      border: 1px solid #003177;
      min-width: 135px;
      height: 60px;
      line-height: 60px;
      font-size: 30px;
      font-weight: 500;
      &.selected {
        color: #fff;
        background: #003177;
      }
    }
  }
  .mob-schedule-list {
    display: none;
    &.actived {
      display: block;
    }
    .mob-type-list {
      padding: 30px 3vw;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .mob-type-item {
        margin-bottom: 6px;
        background: rgb(249, 249, 249);
        color: #2c3e50;
        width: 46vw;
        height: 60px;
        line-height: 60px;
        padding-left: 4vw;
        padding-right: 4vw;
        font-size: 15px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        text-overflow: ellipsis;
        overflow: hidden;
        &.selected {
          background: rgb(224, 224, 224);
        }
      }
    }
  }
  .mob-speech-wrapper {
    display: none;
    &.actived {
      display: block;
    }
    .mob-speech-item {
      margin: 0 auto;
      background: #f9f9f9;
      color: rgb(0, 0, 0);
      width: 94vw;
      padding: 20px;
      margin-bottom: 10px;
      text-align: left;
      h4 {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 10px;
      }
      .mob-lecturer-list {
        .lecturer {
          margin-bottom: 5px;
          font-size: 14px;
          span {
            font-weight: 500;
            margin-right: 5px;
          }
        }
      }
      .time {
        font-size: 14px;
      }
    }
  }
}
</style>